import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
import { Container } from "reactstrap";
import 'bootswatch/dist/spacelab/bootstrap.min.css'
import Index from './components/index';
import Header from './components/header';
import About from './components/about';

function App() {
  return (
    <HashRouter>
      <main role="main"></main>
      <Header />
      <form autoComplete="off">
        <Container className="p-3">
          <Routes>
            <Route path="/" element={<Index />} exact />
            <Route path="/index.html" element={<Index />} exact />
            <Route path="/about" element={<About />} />
          </Routes>
        </Container>
      </form>
    </HashRouter>
  );
}

export default App;
