import React, { useState, useEffect } from "react";
//import { ReadRecord } from './../api'
//import { ReadRecord } from './../api'
//import logo from './../logo.png'; // with import

export default function About() {
  const [version, setVersion] = useState('1.0.0');
  const [apiVersion, setApiVersion] = useState(null);
  const [state, setState] = useState({ loading: false, message: null, error: false });

  useEffect(() => {
    async function fetchData() {
      console.log("[About] getting data");
      //let data = await ReadRecord("Version")
      let data = "ddd"
      setApiVersion(data);
    }
    fetchData();
  }, [])
  return (
    <div className="container">
      <fieldset>
        <p className=" justify-content-center text-center">React Test</p>
        <p className=" justify-content-center text-center">2023</p>
        <p className=" justify-content-center text-center">UI Version:  {version}</p>
        {apiVersion == null ? <p className=" justify-content-center text-center">Api Version: <i className="fa fa-refresh fa-lg fa-spin"></i></p> : <p className=" justify-content-center text-center">Api Version: {apiVersion} </p>}
        <p className=" justify-content-center text-center">Support: <a href="mailto:"></a></p>
        {/* <img src={logo} alt="Logo"/> */}
      </fieldset>
    </div>
  );
}

// export default class About extends Component {

//   state = {};

//   constructor(props) {
//     super(props);
//     var ver = "1.0.0"
//     this.state = { version: ver, apiVersion: "" }

//     // ReadRecord("version")
//     //   .then(response => response.json())
//     //   .then(data => {
//     //     this.setState({ apiVersion: data.version });
//     //   })
//   }

//   render() {
//     return (
//       <div className="container">
//         <fieldset>
//           <p className=" justify-content-center text-center">Dining Venue</p>
//           <p className=" justify-content-center text-center">2022  University</p>
//           <p className=" justify-content-center text-center">UI Version:  {this.state.version}</p>
//           {this.state.apiVersion === "" ? <p className=" justify-content-center text-center">Api Version: <i className="fa fa-refresh fa-lg fa-spin"></i></p> : <p className=" justify-content-center text-center">Api Version: {this.state.apiVersion} </p>}
//           <p className=" justify-content-center text-center">Support: <a href="mailto:"></a></p>
//           {/* <img src={logo} alt="Logo"/> */}
//         </fieldset>
//       </div>
//     );
//   }

// }
