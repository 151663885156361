import React from "react";
//import logo from './../logo.png'; // with import

export default function Index() {
  console.log("[Index]");
  return (
    <div className="container text-center">
      <img src="logo512.png" alt="Italian Trulli"></img>
    </div>
  );
}