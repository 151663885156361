import React, { useState } from "react";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from "react-router-dom";
//import { ReadRecord } from './../api'


export default function Header() {
  const [collapsed, setCollapsed] = useState(true)
  const [state, setState] = useState({ userinfo: { role: 'Administrator' }, isAuthenticated: true, login: 'Signing in...', hidden: false, role: 'none' });
  function logOutHandler() { return; }
  function toggleNavbar() {
    setCollapsed(!collapsed)
  }
  return (
    <header>
      <Navbar className="navbar-expand-md navbar-dark bg-primary mb-3">
        <NavbarBrand className="" tag={Link} to={"/"}>React Test</NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />

        <Collapse isOpen={collapsed} navbar>
          <Nav>
            <NavItem>
              <NavLink tag={Link} className="text-light" to="/venue/venues">Venues</NavLink>
            </NavItem>
            {
              state.userinfo.role === "Administrator"
              &&
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="text-light">Administration</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/admin/category">Category</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/admin/session">Session</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink tag={Link} className="text-dark" to="/admin/period">Period</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            }
            <NavItem>
              <NavLink tag={Link} className="text-light" to="/about">About</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
        <Collapse className="d-sm-inline-flex">
          <Nav className="nav navbar-nav navbar-right glyphicon glyphicon-log-in">
            <UncontrolledDropdown>
              <DropdownToggle nav caret>
                {state.login === "Signing in..." ?
                  <span className="glyphicon glyphicon-log-in">
                    <i className="fa fa-refresh fa-lg fa-spin p-2"></i>
                    {state.login}
                  </span> :
                  <span className="glyphicon glyphicon-log-in mr-2">{state.login}
                  </span>}
                <input type='hidden' id='uid' ref={state.userinfo.uid}></input>
              </DropdownToggle >
              <DropdownMenu end hidden={state.hidden} >
                <DropdownItem >
                  <span className="glyphicon glyphicon-log-out" onClick={logOutHandler}>Log Out</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
}